import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "az",
    resources: {
      en: {
        translation: {
          searchResults: "Search Results For",
          selectCity:"Select City",
          home:"Home",
          search:"Search",
          minPrice:"Min Price",
          maxPrice:"Max Price",
          citySelect: "Select a City",
          vehicles: "Vehicles",
          waterTransport: "Water Transport",
          newAdd: "New Add",
          profile: "Profile",
          login: "Login",
          register: "Register",
          floorNumber: "Floor Number",
          roomCount: "Room Count",
          area: "Area ",
          rentOrSale: "Rent or Sale",
          model: "Model",
          engineSize: "Engine Size",
          mileage: "Mileage",
          fuelType: "Fuel Type",
          gearbox: "Gearbox",
          boatType: "Boat Type",
          length: "Length",
          enginePower: "Engine Power",
          busType: "Bus Type",
          seatCount: "Seat Count",
          select: "Select",
          rent: "Rent",
          sale: "Sale",
          any: "Any",
          bicycle:"Bicycle",
          // NewPostPage Translations
          postTitle: "Title",
          price: "Price",
          city: "City",
          description: "Description",
          type: "Type",
          categorySelect: "Select Category",
          bus: "Bus",
          registrationBadges: "Registration Badges",
          motorcycle: "Motorcycle",
          electronics: "Electronics",
          phone: "Phone",
          laptop: "Laptop",
          tablet: "Tablet",
          numbersSimCard: "Numbers and Sim Cards",
          desktopComputers: "Desktop Computers",
          smartWatch: "Smart Watch",
          spareParts: "Spare Parts",
          carParts: "Car Parts",
          bikeParts: "Bike Parts",
          services: "Services",
          rentalOfequipment: "Rental of Equipment",
          equipmentBiznes: "Equipment for Business",
          nannies: "Nannies and Caregivers",
          photos: "Photo and Video Shooting",
          beauty: "Beauty, Health",
          it: "IT and Telecom",
          logistics: "Logistics",
          clean: "Cleaning",
          translation: "Translation",
          medical: "Medical Services",
          personalItems: "Personal Items",
          clothing: "Clothing",
          accessories: "Accessories",
          hobbies: "Hobbies and Leisure",
          books: "Books",
          sports: "Sports",
          childrenWorld: "Children's World",
          toys: "Toys",
          realEstate: "Real Estate",
          apartments: "Apartments",
          villas: "Villalar",
          land: "Land",
          office: "Offices",
          garage: "Garages",
          jobs: "Job Ads",
          fullTime: "Full-time",
          partTime: "Part-time",
          freelance: "Freelance",
          animals: "Animals",
          dogs: "Dogs",
          cats: "Cats",
          birds: "Birds",
          fish: "Fish",
          horses: "Horses",
          villa: "Villa",
          aboutMe: "About Me",
          updateAcc: "Update Account",
          image: "Image",
          name: "Name",
          logOut: "Logout",
          myProducts: "My Products",
          addNew: "Add new",
          save: "Save",
          welcome: "Welcome",
          username:"username",
          password:"password",
          phoneNumber:"phoneNumber",
          dontHave:"Register",
          createAcc:"Create a Account",
          haveAcc:"Do you have an account?",
          delete:"delete",
          uploadImage:"Upload Image",
          submit:"Add new Ads"
        },
      },
      az: {
        translation: {
          searchResults:"Axtarış nəticələri",
          selectCity:"Şəhər seçin",
          home:"Ana Səhifə",
          minPrice:"Min Qiymət",
          maxPrice:"Max Qiymət",
          search:"Axtarış",
          newAdd: "Yeni Elan",
          citySelect: "Şəhər seçin",
          profile: "Hesab",
          login: "Daxil ol",
          register: "Qeydiyyat",
          any: "Digər",
          vehicles: "Avtomobillər",
          waterTransport: "Su Nəqliyyatı",
          // NewPostPage Translations
          postTitle: "Başlıq",
          price: "Qiymət",
          city: "Şəhər",
          description: "Haqqında",
          type: "Tipi",
          categorySelect: "Kateqoriya Seçin",
          bus: "Avtobuslar",
          registrationBadges: "Qeydiyyat Nişanları",
          motorcycle: "Motosiklet",
          electronics: "Elektronika",
          phone: "Telefon",
          laptop: "Noutbuk",
          tablet: "Tablet",
          numbersSimCard: "Nömrələr və Sim Kart",
          desktopComputers: "Masaüstü Kompüterlər",
          smartWatch: "Ağıllı Saat",
          spareParts: "Ehtiyat hissələri",
          carParts: "Avtomobil hissələri",
          bikeParts: "Velosiped hissələri",
          services: "Xidmət",
          rentalOfequipment: "Avadanlığın icarəsi",
          equipmentBiznes: "Biznes üçün avadanlıq",
          nannies: "Dayələr və Baxıcılar",
          photos: "Foto və Video çəkiliş",
          beauty: "Gözəllik, Sağlamlıq",
          it: "IT və Telekom",
          logistics: "Logistika",
          clean: "Təmizlik",
          translation: "Tərcümə",
          medical: "Tibbi Xidmətlər",
          personalItems: "Şəxsi Əşyalar",
          clothing: "Paltar",
          accessories: "Aksesuarlar",
          hobbies: "Hobbi və Asude vaxt",
          books: "Kitablar",
          sports: "İdman",
          childrenWorld: "Uşaq aləmi",
          toys: "Oyuncaqlar",
          realEstate: "Daşınmaz Əmlak",
          apartments: "Mənzillər",
          villas: "Villalar",
          land: "Torpaq",
          office: "Ofislər",
          garage: "Qarajlar",
          jobs: "İş Elanları",
          fullTime: "Tam ştat",
          partTime: "Yarım ştat",
          freelance: "Freelance",
          animals: "Heyvanlar",
          dogs: "İtlər",
          cats: "Pişiklər",
          birds: "Quşlar",
          fish: "Balıq",
          horses: "Atlar",
          bicycle:"Velosiped",
          ///ProfilePage
          aboutMe: "Haqqımda",
          updateAcc: "Hesabi Yenile",
          image: "Şəkil",
          logOut: "Çıxış",
          name: "Ad",
          myProducts: "Mənim Məhsulum",
          addNew: "Elan Əlave Et",
          save: "Yadda Saxla",
          floorNumber: "Mərtəbə",
          villa: "Villa",
          roomCount: "Otaq Sayı",
          area: "Ölçü ",
          rentOrSale: "Kirə / Satılır",
          model: "Model",
          engineSize: "Mühərrikin Ölçüsü",
          mileage: "Yürüş",
          fuelType: "Yanacaq Növü",
          gearbox: "Sürətlər Qutusu",
          select: "Seçin",
          boatType: "Qayıq növü",
          enginePower: "Mühərrikin Gücü",
          busType: "Avtobusun Növü",
          length: "Uzunluq",
          rent: "Kirə",
          sale: "Satılır",
          propertyType: "Əmlakın Növü",
          gardenHouse: "Bağ evi",
          seatCount: "Oturacaq Sayı",
          courtyardHouse: "Həyət evi",
          officeType: "Ofis Türü",
          welcome: "Xoş Gəldiniz",
          username:"Istifadəçi adı",
          phoneNumber:"telefon nömrəsi",
          password:"şifrə",
          dontHave:"Qeydiyyatdan Keçin",
          createAcc:"Yeni hesab Yaradın",
          haveAcc:"Sizin hesabınız var?",
          delete:"sil",
          uploadImage:"Şəkil Yükləyin",
          submit:"Elan Yerləşdir"
        },
      },
      ru: {
        translation: {
          searchResults:"Результаты поиска",
          selectCity:"Выберите город",
          home:"Дом",
          search:"Поиск",
          newAdd: "Новое",
          minPrice:"мин. цена",
          maxPrice:"макс. цена",
    
          // NewPostPage Translations
          postTitle: "Заголовок",
          citySelect: "выберите город",
          price: "Цена",
          city: "Город",
          description: "Описание",
          type: "Тип",
          translation: "Переводить",
          categorySelect: "Выберите категорию",
          profile: "Профиль",
          login: "Вход",
          register: "Регистрация",
          any: "Другой",
          vehicles: "Транспорт",
          waterTransport: "Водный транспорт",
          bus: "Автобусы",
          registrationBadges: "Регистрационные знаки",
          motorcycle: "Мотоциклы",
          electronics: "Электроника",
          phone: "Телефон",
          laptop: "Ноутбук",
          tablet: "Планшет",
          numbersSimCard: "Номера и Сим-карты",
          desktopComputers: "Настольные компьютеры",
          smartWatch: "Умные часы",
          spareParts: "Запчасти",
          carParts: "Автозапчасти",
          bikeParts: "Запчасти для велосипедов",
          services: "Услуги",
          rentalOfequipment: "Аренда оборудования",
          equipmentBiznes: "Оборудование для бизнеса",
          nannies: "Няни и Уход",
          photos: "Фото и Видео съёмка",
          beauty: "Красота, Здоровье",
          it: "IT и Телеком",
          logistics: "Логистика",
          clean: "Уборка",
          medical: "Медицинские услуги",
          personalItems: "Личные вещи",
          clothing: "Одежда",
          accessories: "Аксессуары",
          hobbies: "Хобби и досуг",
          books: "Книги",
          sports: "Спорт",
          childrenWorld: "Детский мир",
          toys: "Игрушки",
          realEstate: "Недвижимость",
          apartments: "Квартиры",
          villas: "Виллы",
          land: "Земля",
          office: "Офисы и помещения",
          garage: "Гаражи",
          jobs: "Вакансии",
          fullTime: "Полный день",
          partTime: "Частичная занятость",
          freelance: "Фриланс",
          animals: "Животные",
          dogs: "Собаки",
          cats: "Кошки",
          birds: "Птицы",
          fish: "Рыба",
          horses: "Лошади",
          bicycle:"Велосипед",
          ///ProfilePage
          aboutMe: "Обо мне",
          updateAcc: "Обновить учетную запись",
          image: "Изображение",
          name: "Имя",
          logOut: "Выйти",
          myProducts: "Мой продукт",
          addNew: "Добавить объявление",
          save: "Сохранить в память",
          floorNumber: "Этаж",
          roomCount: "Количество комнат",
          area: "Площадь ",
          rentOrSale: "Аренда / Продажа",
          model: "Модель",
          engineSize: "Объем двигателя",
          mileage: "Пробег",
          fuelType: "Тип топлива",
          gearbox: "Коробка передач",
          select: "Выбрать",
          boatType: "Тип лодки",
          length: "Длина",
          enginePower: "Мощность двигателя",
          busType: "Тип автобуса",
          rent: "Аренда",
          sale: "Продажа",
          propertyType: "Тип недвижимости",
          seatCount: "Количество мест",
          courtyardHouse: "Дом во дворе",
          villa: "Вилла",
          welcome: "Добро пожаловать",
          username:"имя",
          phoneNumber:"номер телефона",
          password:"пароль",
          dontHave:"зарегистрироваться",
          createAcc:"Создать новую учетную запись",
          haveAcc:"У вас есть учетная запись?",
          delete:"удалить",
          uploadImage:"Загрузить изображение",
          submit:"Добавить новые объявления"
        },
      },
    },
  });

