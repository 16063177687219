import React from 'react';
import { useParams } from 'react-router-dom';

const FilteredPage = () => {
  const { subcategory } = useParams(); 

  return (
    <div className="filtered-page">
      <h1>Filtered Page</h1>
      <p>Showing results for: <strong>{subcategory}</strong></p>
      <div>
        <p>{subcategory} ile ilgili içerikler burada listelenecek.</p>
      </div>
    </div>
  );
};

export default FilteredPage;
